@import "transition-style";


.transition {
    height: 100%;
    width: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.349);
}

@keyframes wipe-out-bottom-left {
    from {
      clip-path: polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%);
    }
    to {
      clip-path: polygon(-50% 50%, 50% 150%, 50% 150%, -50% 50%);
    }
  }
  
  [transition-style="out:wipe:bottom-left"] {
    animation: 1s cubic-bezier(.25, 1, .30, 1) wipe-out-bottom-left both!important;
  }

@keyframes square-in-center {
    from {
      clip-path: inset(100% 100% 100% 100%);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }
  
  [transition-style="in:square:center"] {
    animation: 1s cubic-bezier(.25, 1, .30, 1) square-in-center both!important;
  }

@keyframes diamond-in-center {
    from {
      clip-path: polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%);
    }
    to {
      clip-path: polygon(-50% 50%, 50% -50%, 150% 50%, 50% 150%);
    }
  }
  
  [transition-style="in:diamond:center"] {
    animation: 3s cubic-bezier(.25, 1, .30, 1) diamond-in-center both!important;
  }

@keyframes square-in-top-left {
    from {
      clip-path: inset(100% 0 0 100%);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }
  
  [transition-style="in:square:top-left"] {
    animation: 1s cubic-bezier(.25, 1, .30, 1) square-in-top-left both!important;
  }


@keyframes wipe-in-right {
    from {
      clip-path: inset(0 100% 0 0);
    }
    to {
      clip-path: inset(0 0 0 0);
    }
  }
  
  [transition-style="in:wipe:right"] {
    animation: 2s cubic-bezier(.25, 1, .30, 1) wipe-in-right both!important;
  }