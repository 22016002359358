.light-theme-container {
    width: 100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: 800ms;
    overflow: hidden;
}

.light-theme-container .light-imgs-container {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.light-theme-container img{
    position: absolute;
}


.light-theme-container .black1-img {
    width: 45%; /* 45*/
    height: 35%;  /* 45 */
    bottom: 55px;  /* 0 */
    right: -15px; /* 0px */
    opacity: 0.3; /* 0.4 */
    
}


.light-theme-container .black2-img {
    width: 100%;
    height: 100%;
    bottom: 0%;
    left: -10%;
    opacity: 0.1;
}


.squares {
    padding: 0;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
}

.squares li {
    list-style: none;
    position: absolute;
    background-color: rgba(141, 141, 141, 0.267);
    border-radius: 4px;
    animation: goingUp 10s linear infinite;
    bottom: -20%;
}

.squares li:nth-child(1) {
    left: 17%;
    width: 35px;
    height: 35px;
    animation-duration: 9s;
    animation-delay: 2s;
}

.squares li:nth-child(2) {
    left: 5%;
    width: 100px;
    height: 100px;

    animation-duration: 10s;
    animation-delay: 5s;
}

.squares li:nth-child(3) {
    left: 58%;
    width: 20px;
    height: 20px;
    animation-duration: 14s;
    animation-delay: 3s;
}

.squares li:nth-child(4) {
    left: 87%;
    width: 40px;
    height: 40px;
    animation-duration: 9s;
    animation-delay: 5s;
}

.squares li:nth-child(5) {
    left: 41%;
    width: 82px;
    height: 82px;
    animation-duration: 11s;
    animation-delay: 8s;
}

.squares li:nth-child(6) {
    left: 90%;
    width: 120px;
    height: 120px;
    animation-duration: 14s;
    animation-delay: 6s;
}

.squares li:nth-child(7) {
    left: 48%;
    width: 15px;
    height: 15px;
    animation-duration: 16s;
    animation-delay: 8s;
}

.squares li:nth-child(8) {
    left: 28%;
    width: 75px;
    height: 75px;
    animation-duration: 12s;
    animation-delay: 5s;
}

@keyframes goingUp {
    0% {
        transform: translateY(0) rotate(0);
        opacity: 1;
    }

    100% {
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
    }
}

@media screen and (min-width: 600px) {
    .light-theme-container .black1-img {
        width: 45%; /* 45*/
        height: 45%;  /* 45 */
        bottom: 0;  /* 0 */
        right: 0; /* 0px */

    }
}


@media screen and (min-width: 700px) {
    .light-theme-container .black1-img {
        width: 30%; /* 45*/
        height: 55%;  /* 45 */
        bottom: 0;  /* 0 */
        right: 0; /* 0px */

    }
}



@media screen and (min-width: 1300px) {
    .light-theme-container .black1-img {
        width: 20%; /* 45*/
        height: 45%;  /* 45 */
        bottom: 0;  /* 0 */
        right: 0; /* 0px */

    }
}